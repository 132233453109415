<template>
  <b-modal
    id="modal-3"
    v-model="showModel"
    title="Attachments"
    hide-footer
    size="lg"
    centered
  >
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <table class="table table-centered table-nowrap mb-0">
                <thead class="table-light">
                  <tr>
                    <td>#</td>
                    <td>Media Type</td>
                    <td>Media Link</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in media" v-bind:key="index">
                    <td>{{ index++ }}</td>
                    <td>{{ item.media_type }}</td>
                    <td>
                      <a v-bind:href="item.media_path" target="_blank">{{
                        item.media_path
                      }}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="text-end">
              <button
                @click="closeModel"
                type="button"
                class="btn btn-primary close"
                aria-label="Close"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "Attachments",
  props: ["media"],
  data() {
    return {
      showModel: false,
    };
  },
  methods: {
    closeModel() {
      this.showModel = false;
    },
  },
};
</script>