<template>
  <b-modal
    id="modal-1"
    v-model="showModel"
    title="Reject Claim"
    hide-footer
    size="lg"
    centered
  >
    <div class="card-body">
      <form @submit.prevent="getData">
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="subject">Rejection Reason</label>
              <!-- {{ form.rejection_reason }} -->
              <textarea
                class="form-control"
                id="subject"
                rows="3"
                v-model="form.rejection_reason"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="text-end">
              <SubmitButton
                :processing="processing"
                type="submit"
                class="btn btn-primary waves-effect waves-light w-md"
                text="Reject the Claim"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: ["claim_id"],
  data() {
    return {
      processing: false,
      showModel: false,
      form: {
        rejection_reason: null,
        status: "Rejected",
      },
    };
  },
  methods: {
    getData() {
      this.processing = true;
      this.form.id = this.claim_id;
      // console.log(this.form);
      this.$axios
        .post("rejection-reason", this.form)
        .then((response) => {
          this.showModel = false;
          this.processing = false;
          this.triggerSwal(response.data.message, "success");
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
          this.processing = false;
        });
    },
  },
};
</script>